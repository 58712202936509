import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toElement as scrollToElement } from 'utils/scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faPalette, faFlask, faLandmark, faFingerprint } from '@fortawesome/free-solid-svg-icons'

import './style.scss';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      isSticky: false
    };
  }

  componentDidMount() {
    window.addEventListener('load', this.handleScroll);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const boundingRect = ((document || {}).documentElement || {})
      .getBoundingClientRect;
    if (boundingRect) {
      if (document.documentElement.getBoundingClientRect().top * -1 > 100)
        this.setState({ isSticky: true });
      else this.setState({ isSticky: false });
    }
    
    const pages = ['.about-page','.timeline-page','.skills-page','.portfolio-page'];
    const offset = 100;
    Object.keys(pages).map(function(key) {
        const nextPage = document.querySelector(pages[key]);
        const top = nextPage.getBoundingClientRect().top;
        const result = (top + offset) >= 0 && (top + offset) <= window.innerHeight;
        if(result) {
          if(document.querySelector('.active')) { 
                          document.querySelector('.active').classList.remove("active");  }
        document.querySelector(pages[key] + '-h').classList.add("active");
                  document.querySelector(pages[key]).classList.add("displayed");

    if(document.querySelector('.activeP')) { 
      document.querySelector('.activeP').classList.remove("activeP");  }
    
    if(document.querySelector('.activeN')) { 
      document.querySelector('.activeN').classList.remove("activeN");  }
    
          if(document.querySelector(pages[key] + ' .scroll-to-previous')) document.querySelector(pages[key] + ' .scroll-to-previous').classList.add("activeP");
          if(document.querySelector(pages[key] + ' .scroll-to-next')) document.querySelector(pages[key] + ' .scroll-to-next').classList.add("activeN");
          
          }
    });
  }

  scrollToPage(pageSelector) {
    const nextPage = document.querySelector(pageSelector);
    scrollToElement(nextPage);
  }

  render() {
    const {
      theme: { colorPrimary, bgPrimary, navAlpha }
    } = this.context;

    const stickyClass = this.state.isSticky ? 'topPosition' : '';
    const stickyStyles = this.state.isSticky
      ? { backgroundColor: navAlpha, color: colorPrimary }
      : { backgroundColor: bgPrimary, color: colorPrimary };
    
    return (
      <nav
        className={stickyClass}
        ref={(elem) => {
          this.nav = elem;
        }}
        style={stickyStyles}
      >
        <style jsx="true">
          {`
            
            .menu__item.active {
              opacity: 1;
              font-weight: bold;
              border-bottom: 2px solid ${colorPrimary};
            }
          `}
        </style>
        <div className="menu">
          <div
            className="menu__item about-page-h"
            onClick={(e) => this.scrollToPage('.about-page')}
          >
            <FontAwesomeIcon icon={faFingerprint} className="fa-xl" />
            <div className="label">About</div>
          </div>
          <div
            className="menu__item timeline-page-h"
            onClick={(e) => this.scrollToPage('.timeline-page')}
          >
            <FontAwesomeIcon icon={faLandmark} className="fa-xl" />
            <div className="label">CV</div>
          </div>
          <div
            className="menu__item skills-page-h"
            onClick={(e) => this.scrollToPage('.skills-page')}
          >
            <FontAwesomeIcon icon={faFlask} className="fa-xl" />
            <div className="label">Skills</div>
          </div>
          <div
            className="menu__item portfolio-page-h"
            onClick={(e) => this.scrollToPage('.portfolio-page')}
            title="Projects"
          >
            <FontAwesomeIcon icon={faPalette} className="fa-xl" />
            <div className="label">Projects</div>
          </div>
        </div>
      </nav>
    );
  }
}

Nav.contextTypes = {
  theme: PropTypes.any
};

export default Nav;
