import React from "react";
import PropTypes from "prop-types";
import ScrollToPrevious from 'components/ScrollToPrevious';
import ScrollToNext from "components/ScrollToNext";
import { toElement as scrollToElement } from "utils/scroll";
import DiskMeter from "components/DiskMeter";

import "./style.scss";

const SkillsPage = (props, context) => {
    const {
        theme: { colorPrimary, colorHighlight }
    } = context;

    let dragIcon;

    function onDragStart(e) {
        e.currentTarget.style.opacity = ".4";
        e.currentTarget.style.animation = "tossing 1s infinite cubic-bezier(0.64, 0.57, 0.67, 1.53)";
        dragIcon = e.currentTarget;
        e.dataTransfer.setDragImage(dragIcon, 75, 75);
        e.dataTransfer.setData("id", e.target.id);
        document.querySelector('.inspect').innerHTML = " Values by years of experience. Why don't you try to mix two skill flasks?";
    }

    function onDragEnd(e) {
        e.currentTarget.style.opacity = "1";
        e.currentTarget.style.animation = "";
    }

    function onDragLeave(e) {
        if (dragIcon !== e.currentTarget) {
            e.currentTarget.style.animation = "";
        }
    }

    function onDragOver(e) {
        e.preventDefault();

        if (dragIcon !== e.currentTarget) {
            e.currentTarget.style.animation = "shake 2s infinite";
        }

    }

    function onMouseOver(e) {
        e.preventDefault();
        document.querySelector('.inspect-2').innerHTML = e.currentTarget.getAttribute('data-inspect');
    }

    function onMouseOut(e) {
        e.preventDefault();
        document.querySelector('.inspect-2').innerHTML = "";
    }

    function onDrop(e) {
        if (document.querySelector(".open"))
            document.querySelector(".open").classList.remove("open");
        document.body.classList.remove("open-modal");
        e.preventDefault();
        console.log(e.dataTransfer.getData("id"));
        e.currentTarget.style.animation = "";
        const data = e.dataTransfer.getData("id");

        let items = [];

        let portValues = {
            _1: "BEI", // Sushi Mania
            _3: "ABGH", // High Play
            _4: "BEI", // Magicash
            _2: "BEI", // CashInk
            _5: "ABCGHIJ", // Volta ao Mundo
            _6: "ALK", // EatPortugallo
            _7: "ABCD", // Floppy Disk
            _8: "GHIJLK", // Doce.Fit
            _9: "ABCGHIJL", //AEISTECP
            _10: "ABCEGHIJ", // Stargame
            _11: "B", // OLX
            _12: "ABJ", // TripleClient
            _13: "ABG" // Personal Website

            // A Front end
            // B UI/UX
            // C Project
            // D Backend
            // E Animation
            // G SEO
            // H Social Media
            // I Graphic design
            // J Office
            // K CMS / Marketing
            // L eCommerce

        };

        Object.keys(portValues).map(key => {
            if (
                portValues[key].indexOf(data) > -1 &&
                portValues[key].indexOf(e.currentTarget.id) > -1
            ) {
                items.push(key);
            }
        });
        if (items.length === 0) {
            document.querySelector('.inspect').innerHTML = "No records found for those two flasks. Perhaps you could give me challenge?";
        } else {
            const portfolio = document.querySelector(".portfolio-page");
            scrollToElement(portfolio);

            var item = items[Math.floor(Math.random() * items.length)];
            document.querySelector("." + item).classList.add("open");
            document.querySelector('.portfolio-h1').classList.add('opentitle');
            document.body.classList.add("open-modal");
        }


    }

    const eventHandlers = {
        onDragStart,
        onDragOver,
        onDragLeave,
        onDragEnd,
        onDrop,
        onMouseOver,
        onMouseOut,
    };

    return (
        <div className="skills-page" >
      <style jsx="true">
        {`
          .highlight {
            background-color: ${colorHighlight};
          }
          .inspect, .inspect-2 {
            color: ${colorPrimary};
          }
        `}
      </style>
      <div className="content-grid">
      <ScrollToPrevious pageSelector=".timeline-page" />
        <h1 style={{ color: colorPrimary }}>Skills</h1>
        <div className="skills-wrapper">
          <div
            className="flask-item"
            id="A"
            draggable
            {...eventHandlers}
            data-inspect="jQuery, SCSS, React/Angular and Webpack">
            <DiskMeter
              value={70}
              size={100}
              backgroundColor={colorHighlight}
              borderColor={colorPrimary}
              textColor={colorPrimary}
            />
            <p className="text-emoji" style={{ color: colorPrimary }}>
              Front-end 
            </p>
          </div>
          <div
            className="flask-item"
            id="B"
            draggable
            {...eventHandlers}
            data-inspect="Figma, Sketch and Adobe XD"
          >
            <DiskMeter
              value={80}
              size={100}
              backgroundColor={colorHighlight}
              borderColor={colorPrimary}
              textColor={colorPrimary}
            />
            <p className="text-emoji" style={{ color: colorPrimary }}>
              UI and UX
            </p>
          </div>
          <div
            className="flask-item"
            id="C"
            draggable
            {...eventHandlers}
            data-inspect="Agile, scrum, Jira and Confluence"
          >
            <DiskMeter
              value={40}
              size={100}
              backgroundColor={colorHighlight}
              borderColor={colorPrimary}
              textColor={colorPrimary}
            />
            <p className="text-emoji" style={{ color: colorPrimary }}>
              Project Management
            </p>
          </div>
          <div
            className="flask-item"
            id="D"
            draggable
            {...eventHandlers}
            data-inspect="PHP, MySQL, Laravel, Magento and Node.js"
          >
            <DiskMeter
              value={40}
              size={100}
              backgroundColor={colorHighlight}
              borderColor={colorPrimary}
              textColor={colorPrimary}
            />
            <p className="text-emoji" style={{ color: colorPrimary }}>
              PHP
            </p>
          </div>
          <div
            className="flask-item"
            id="E"
            draggable
            {...eventHandlers}
            data-inspect="After Effects, Web animation and Unity"
          >
            <DiskMeter
              value={60}
              size={100}
              backgroundColor={colorHighlight}
              borderColor={colorPrimary}
              textColor={colorPrimary}
            />
            <p className="text-emoji" style={{ color: colorPrimary }}>
              Motion Graphics
            </p>
          </div>
          <div
            className="flask-item"
            id="I"
            draggable
            {...eventHandlers}
            data-inspect="Photoshop, Illustrator"
          >
            <DiskMeter
              value={80}
              size={100}
              backgroundColor={colorHighlight}
              borderColor={colorPrimary}
              textColor={colorPrimary}
            />
            <p className="text-emoji" style={{ color: colorPrimary }}>
              Graphic Design
            </p>
          </div>
          <div
            className="flask-item"
            id="J"
            draggable
            {...eventHandlers}
            data-inspect="Sharepoint, Excel"
          >
            <DiskMeter
              value={80}
              size={100}
              backgroundColor={colorHighlight}
              borderColor={colorPrimary}
              textColor={colorPrimary}
            />
            <p className="text-emoji" style={{ color: colorPrimary }}>
              Microsoft 365
            </p>
          </div>
          <div
            className="flask-item"
            id="C"
            draggable
            {...eventHandlers}
            data-inspect="Adobe Experience Manager, Joomla and Wordpress"
          >
            <DiskMeter
              value={60}
              size={100}
              backgroundColor={colorHighlight}
              borderColor={colorPrimary}
              textColor={colorPrimary}
            />
            <p className="text-emoji" style={{ color: colorPrimary }}>
              Content Management Systems
            </p>
          </div>

<div
            className="flask-item"
            id="L"
            draggable
            {...eventHandlers}
            data-inspect="Magento, Adobe Campaign and Oracle Commerce Cloud"
          >
            <DiskMeter
              value={30}
              size={100}
              backgroundColor={colorHighlight}
              borderColor={colorPrimary}
              textColor={colorPrimary}
            />
            <p className="text-emoji" style={{ color: colorPrimary }}>
              eCommerce
            </p>
          </div>

<div
            className="flask-item"
            id="G"
            draggable
            {...eventHandlers}
            data-inspect="Excel, Adobe Analytics and Google Analytics"
          >
            <DiskMeter
              value={60}
              size={100}
              backgroundColor={colorHighlight}
              borderColor={colorPrimary}
              textColor={colorPrimary}
            />
            <p className="text-emoji" style={{ color: colorPrimary }}>
              SEO
            </p>
          </div>

<div
            className="flask-item"
            id="H"
            draggable
            {...eventHandlers}
            data-inspect="LinkedIn for Companies, Twitter and Facebook for business"
          >
            <DiskMeter
              value={80}
              size={100}
              backgroundColor={colorHighlight}
              borderColor={colorPrimary}
              textColor={colorPrimary}
            />
            <p className="text-emoji" style={{ color: colorPrimary }}>
              Social media
            </p>
          </div>

<div
            className="flask-item"
            id="K"
            draggable
            {...eventHandlers}
            data-inspect="Digital marketing and Adobe Marketing Cloud"
          >
            <DiskMeter
              value={60}
              size={100}
              backgroundColor={colorHighlight}
              borderColor={colorPrimary}
              textColor={colorPrimary}
            />
            <p className="text-emoji" style={{ color: colorPrimary }}>
              Branding
            </p>
          </div>
        </div>

        <p className="inspect">... by years of experience. Why don't you mix two flasks?</p>
        <p className="inspect-2"></p>
      </div>
      <ScrollToNext pageSelector=".portfolio-page" />
    </div>
    );
};

SkillsPage.contextTypes = {
    theme: PropTypes.any
};

export default SkillsPage;