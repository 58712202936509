import React from "react";
import PropTypes from "prop-types";
import ScrollToNext from "components/ScrollToNext";
import ScrollToPrevious from 'components/ScrollToPrevious';
import { toElement as scrollToElement } from "utils/scroll";
import "./style.scss";

import timelineItems from "./timeline-items";
import timelineItemsFull from "./timeline-items-full";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const TimelinePage = (props, context) => {
  const {
    theme: { colorPrimary, colorHighlight, textPrimary, textAlternate }
  } = context;

  function showAll(e) {
    e.preventDefault();
    const portfolio = document.querySelector(".timeline-page");
    scrollToElement(portfolio);

    const pages = document.querySelectorAll(".vertical-timeline");
    document.querySelector(".open-timeline").classList.add("hide");

    Object.keys(pages).map(function(key) {
      pages[key].classList.toggle("hide");
    });
  }

  return (
    <div className="timeline-page">
    <ScrollToPrevious pageSelector=".about-page" />
      <style jsx="true">
        {`
          .highlight {
            background-color: ${colorHighlight};
          }
          .timeline-page .vertical-timeline::before {
            background: ${colorPrimary};
            background: -webkit-linear-gradient(
              top,
              ${colorPrimary} 0%,
              ${colorPrimary} 75%,
              rgba(255, 255, 255, 0) 100%
            );
            background: -o-linear-gradient(
              top,
              ${colorPrimary} 0%,
              ${colorPrimary} 75%,
              rgba(255, 255, 255, 0) 100%
            );
            background: linear-gradient(
              to bottom,
              ${colorPrimary} 0%,
              ${colorPrimary} 75%,
              rgba(255, 255, 255, 0) 100%
            );
          }
          .timeline-page .vertical-timeline-element-icon {
            border: 5px solid ${colorPrimary};
              box-shadow: 0 6px 12px 0 rgba(0,0,0,0.2);
          }

          
            .small, h3, h4, a {
              color: ${colorHighlight};
            }
            a {
              color: ${textAlternate};
              font-weight: 400;
            }

            .vertical-timeline-element-date {
                color: ${textPrimary};
            }

            .vertical-timeline-element-content
             {
              background: ${colorPrimary};
              box-shadow: 0 6px 12px 0 rgba(0,0,0,0.2);
              backdrop-filter: blur( 4px );
              -webkit-backdrop-filter: blur( 4px );
              border-radius: 10px;
              border: 1px solid rgba( 255, 255, 255, 0.18 );
            }
            .vertical-timeline-element-content-arrow {
                border: 7px solid rgba( 255, 255, 255, 0);
                border-right: 7px solid ${colorPrimary};
                opacity: .50;
                box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
            }

            .open-timeline a {
              color: ${colorPrimary};
            }

          }

          
        `}
      </style>
      <div className="content-grid">
        <h1 style={{ color: colorPrimary }}>Experience</h1> {/*Timeline*/}
        <VerticalTimeline>
          {/* <TimelineItem /> */}
          {timelineItems.map((item, i) => (
            <VerticalTimelineElement
              date={item.date}
              icon={item.icon}
              className={item.className}
              key={i}
            >
              {item.render}
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
        <VerticalTimeline className="hide">
          {/* <TimelineItem /> */}
          {timelineItemsFull.map((item, i) => (
            <VerticalTimelineElement
              date={item.date}
              icon={item.icon}
              className={item.className}
              key={i}
            >
              {item.render}
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
        <p className="open-timeline">
          Want to know the full story? {" "}
          <a href="javascript:;" onClick={e => showAll(e)}>
            Click here!
          </a>
        </p>
      </div>
      <ScrollToNext pageSelector=".skills-page" />
    </div>
  );
};

TimelinePage.contextTypes = {
  theme: PropTypes.any
};

export default TimelinePage;
