import React, { Component } from 'react';
import Nav from 'components/Nav';
import LandingPage from 'pages/LandingPage';
import AboutPage from 'pages/AboutPage';
import TimelinePage from 'pages/TimelinePage';
import SkillsPage from 'pages/SkillsPage';
import PortfolioPage from 'pages/PortfolioPage';
import ScrollTop from 'components/ScrollTop';
import ThemeSwitcher from 'components/ThemeSwitcher';

class App extends Component {

  render() {
    return (
      <div className="dev-landing-page">
        <ThemeSwitcher>
          <Nav />
          <LandingPage />
          <AboutPage />
          <TimelinePage />
          <SkillsPage />
          <PortfolioPage />
          <ScrollTop />
        </ThemeSwitcher>
      </div>
    );
  }
}

export default App;
