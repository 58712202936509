import React from 'react';
import PropTypes from 'prop-types';
import PortfolioItem from 'components/PortfolioItem';
import ScrollToPrevious from 'components/ScrollToPrevious';
import portfolioItems from './portfolio-items';
import Footer from 'components/Footer';

import './style.scss';

const PortfolioPage = (props, context) => {
    const {
        theme: { colorPrimary, colorAlternate, colorHighlight, textAlternate }
    } = context;

    return (
        <div className="portfolio-page">
      <ScrollToPrevious pageSelector=".skills-page" />
      <div className="content-grid">
        <h1 style={{ color: colorPrimary }} className="portfolio-h1">Portfolio</h1> {/*Portfolio*/}
        <div className="portfolio-wrapper">
          <style jsx="true">
            {`
              .flip-card-back, .flip-card-back a   {
                background-color: ${colorPrimary};
                color: ${textAlternate};
              }

              .content-container, .content-container a {
                background-color: ${colorPrimary};
                color: ${textAlternate};
              }

              .overflow-container {
                    scrollbar-width: thin;
                    scrollbar-color: ${colorHighlight} ${colorPrimary};
                  }
                          .overflow-container::-webkit-scrollbar-track {
                            background-color: ${colorPrimary};
                          }

                          .overflow-container::-webkit-scrollbar-track:hover, .overflow-container::-webkit-scrollbar-track:active {
                            background-color: ${colorPrimary};
                          }

                          .overflow-container::-webkit-scrollbar-thumb {
                            background-color: ${colorHighlight};
                          }

                          .overflow-container::-webkit-scrollbar-thumb:hover, .overflow-container::-webkit-scrollbar-thumb:active {
                            background-color: ${colorHighlight};
                          }
              

              .portfolio-item .flip-card-front .portfolio-item__title {
                color: ${colorPrimary};
              }
              .portfolio-item__links *:hover  {
                border-bottom: 2px solid ${colorAlternate};
              }
              .portfolio-item small a:hover, .portfolio-item p a:hover  {
                border-bottom: .5px solid ${colorAlternate};
                opacity: 0.75;
              }
                  @media (max-width: 768px) {
              .flip-card-front   {
                    color: ${textAlternate};
                    background-color: ${colorPrimary};
                  }

                                .portfolio-item .flip-card-front .portfolio-item__title {
                color: ${textAlternate};
              }
                }

            `}
          </style>
          {/* <PortfolioItem /> */}
          {portfolioItems.map((item, i) => (
            <PortfolioItem render={item.render} key={i}/>
          ))}
        </div>
      </div>
      <Footer />
    </div>
    );
};

PortfolioPage.contextTypes = {
    theme: PropTypes.any
};

export default PortfolioPage;