import React from 'react';
import PropTypes from 'prop-types';
import SocialIcons from 'components/SocialIcons';
import ScrollToNext from 'components/ScrollToNext';
import photo from 'images/pic.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faReact } from '@fortawesome/free-brands-svg-icons'

import './style.scss';

const LandingPage = (props, context) => {
  const { theme: { colorPrimary, colorHighlight, textPrimary, colorAlternate } } = context;

  return (
    <div className="landing-page">
     <style jsx="true">
        {`
* {
  scrollbar-width: thin;
  scrollbar-color: ${colorHighlight} ${colorAlternate};
}

*::-webkit-scrollbar-track {
  background-color: ${colorAlternate};
}

*::-webkit-scrollbar-track:hover, *::-webkit-scrollbar-track:active {
  background-color: ${colorAlternate};
}

*::-webkit-scrollbar-thumb {
  background-color: ${colorHighlight};
}

*::-webkit-scrollbar-thumb:hover, *::-webkit-scrollbar-thumb:active {
  background-color: ${colorPrimary};
}
        `}
      </style>
      <main style={{ color: colorPrimary }}>
        <div className="intro-wrapper">
          <img className="intro-img" src={photo} alt="eduardo martins" style={{ borderColor: colorPrimary }}/>
          <div className="intro-name">Eduardo Martins</div>
          <div className="tagline">
            Gamer | Digital |  UX | Design
          </div> 
          <SocialIcons />
        </div>
        <small>Website powered by React <FontAwesomeIcon icon={faReact} /></small>
      </main>
      <ScrollToNext pageSelector=".about-page"/>
    </div>
  );
};

LandingPage.contextTypes = {
  theme: PropTypes.any
};

export default LandingPage;
