import React from 'react';
import { toElement as scrollToElement } from 'utils/scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faBriefcase,
          faBuildingColumns,
          faBaby,
          faComputer,
          faAward,
          faCarOn,
          faGraduationCap,
          faHouseFlag,
          faHandshakeAngle,
          faTruckMoving
 } from '@fortawesome/free-solid-svg-icons'



import extinalarmes from 'images/t/extinalarmes.png';
import fmq from 'images/t/fmq.png';
import highplay from 'images/t/highplay.svg';
import tripleplay from 'images/t/tripleplay.png';
import euroclear from 'images/t/euroclear.png';
import esic from 'images/p/journeys/esic.png';
import serralves from 'images/p/journeys/serralves.png';
import dfc from 'images/p/journeys/dfc.png';

function expandItem(e) {
    e.preventDefault();
    if(document.querySelector('.open')) {document.querySelector('.open').classList.remove('open');}
    const portfolio = document.querySelector('.portfolio-page');
    scrollToElement(portfolio);

    document.querySelector('._' + e.target.id).classList.add('open');
    document.querySelector('.portfolio-h1').classList.add('opentitle');
    document.body.classList.add('open-modal');
}

export default [{
  date: 'November 1991',
  className: "life",
  icon: <FontAwesomeIcon icon={faBaby} />,
	render: (
            <div>
              <h3 className="vertical-timeline-element-title">I was born!</h3>
              <h4 className="vertical-timeline-element-subtitle">Porto, Portugal</h4>
           </div>
    )
	},
	{
  date: "2001",
  className: "degree",
  icon: <FontAwesomeIcon icon={faComputer} />,
	render: (
            <div>
              <h3 className="vertical-timeline-element-title">My first computer degree</h3>
              <h4 className="vertical-timeline-element-subtitle">Porto, Portugal</h4>
              <p className="small">I was 10 when I saw a computer for the first time. Needless to say, I became a Microsoft Office master...</p>
            </div>
		)
	},
	{
  date: "September 2008",
  className: "school",
  icon: <FontAwesomeIcon icon={faBuildingColumns} />,
  render: (
            <div>
              <h3 className="vertical-timeline-element-title">Computer Programming</h3>
              <h4 className="vertical-timeline-element-subtitle">Vocational degree | Escola Daniel Faria</h4>
              <h4 className="vertical-timeline-element-subtitle">Porto, Portugal</h4>
              <p className="small">I pursued a conventional scientific degree during high school, but I wasn't happy. After some research and conflicts with my parents (who wanted me to be a doctor), I applied for a vocational degree in computer programming.</p>
            </div>
    )
  },
  {
  date: "June 2010",
  className: "award",
  icon: <FontAwesomeIcon icon={faAward} />,
  render: (
            <div>
              <h3 className="vertical-timeline-element-title">Prémio monIT 2010 Finalist</h3>
              <h4 className="vertical-timeline-element-subtitle">Instituto Superior Técnico</h4>
              <h4 className="vertical-timeline-element-subtitle">Lisboa, Portugal</h4>
              <p className="small">An annual contest about the myths of radio frequencies. We got to the finals against 9 different projects, selected from hundreds of them. We didn't win...</p>
            </div>
    )
  },
  {
  date: "January 2011",
  className: "work",
  icon: <FontAwesomeIcon icon={faBriefcase} />,
  render: (
            <div>
              <img className="vertical-timeline-element-img bounce" alt="extinalarmes"
                   src={extinalarmes}/>
              <h3 className="vertical-timeline-element-title">Digital Designer</h3>
              <h4 className="vertical-timeline-element-subtitle">1 year | ExtinAlarmes Lda.</h4>
              <h4 className="vertical-timeline-element-subtitle">Paredes, Portugal</h4>
              <p className="small">My first job! I was responsible for developing new databases for surveillance systems and fire extinguisher management. I was also tasked with creating a new corporate identity, along with a new website, Facebook page, and even had the opportunity to record and master a radio advertisement that would be played on local networks.</p>
            </div>
    )
  },
  {
  date: 'July 2011',
  className: "life",
  icon: <FontAwesomeIcon icon={faCarOn} />,
  render: (
            <div>
              <h3 className="vertical-timeline-element-title">Got my driver licence</h3>
              <h4 className="vertical-timeline-element-subtitle">Porto, Portugal</h4>
           </div>
    )
  },
  {
  date: "July 2011",
  className: "award",
  icon: <FontAwesomeIcon icon={faGraduationCap} />,
  render: (
            <div>
              <h3 className="vertical-timeline-element-title">Honorary certificate</h3>
              <h4 className="vertical-timeline-element-subtitle">Associação de Municípios do Vale do Sousa</h4>
              <h4 className="vertical-timeline-element-subtitle">Lousada, Portugal</h4>
              <p className="small">Somehow, I completed my vocational course units with the highest average grade and received an honorary certificate. Such an honor!</p>
            </div>
    )
  },
  {
  date: "December 2011",
  className: "school",
  icon: <FontAwesomeIcon icon={faBuildingColumns} />,
  render: ( 
            <div>
              <h3 className="vertical-timeline-element-title">Multimedia development & production</h3>
              <h4 className="vertical-timeline-element-subtitle">Bachelor degree | CINEL - Centro da Indústria Electrónica, Energia, Telecomunicações e Tecnologias</h4>
               <h4 className="vertical-timeline-element-subtitle">Porto, Portugal</h4>
              <p className="small">Despite my work with ExtinAlarmes, I didn't want to leave my studies behind. After a tedious selection process, I started a Multimedia Development & Production degree, studying web and mobile development frameworks, digital marketing and advertising, illustration and graphic design, storytelling and project management, and other multimedia related content. This degree was quite demanding due to all units being taught in a short amount of time, together with the fact that 90% of it was just hands-on projects.</p>
            </div>
    )
  },
  {
  date: "September 2012",
  className: "work",
  icon: <FontAwesomeIcon icon={faBriefcase} />,
  render: (
            <div>
              <img className="vertical-timeline-element-img bounce" alt="fabamaq"
                   src={fmq}/>
              <h3 className="vertical-timeline-element-title">Developer + UI Designer</h3>
              <h4 className="vertical-timeline-element-subtitle">6 years | FABAMAQ - Sistemas Informáticos, Lda.</h4>
              <h4 className="vertical-timeline-element-subtitle">Porto, Portugal</h4>
              <p className="small">A new challenge approaches! This was my first thought when I visited FMQ Headquarters for the first time. My first task was to assemble a platform that would equip creatives with a tool similar to <a href="javascript:;" id="7" onClick={(e)=>expandItem(e)}>GitHub</a> and software scriptwriting. Since I was integrated with the Creative Core, I was able to design some <a href="javascript:;" id="1" onClick={(e)=>expandItem(e)}>games</a> as part of my job.</p>
            </div>
    )
  },
  {
  date: 'August 2013',
  className: "life",
  icon: <FontAwesomeIcon icon={faHouseFlag} />,
  render: (
            <div>
              <h3 className="vertical-timeline-element-title">Rented my own apartment!</h3>
              <h4 className="vertical-timeline-element-subtitle">Porto, Portugal</h4>
           </div>
    )
  },
  {
  date: "September 2014",
  className: "school",
  icon: <FontAwesomeIcon icon={faBuildingColumns} />,
  render: (
            <div>
              <h3 className="vertical-timeline-element-title">Multimedia engineering</h3>
              <h4 className="vertical-timeline-element-subtitle">Licentiate degree | ISTEC - Instituto Superior de Tecnologias Avançadas</h4>
              <h4 className="vertical-timeline-element-subtitle">Porto, Portugal</h4>
              <p className="small">Multimedia engineering was the follow-up of my CINEL degree, with units going from Java, PHP, JavaScript to Marketing and Advertising, 3D and 2D composition, sound and video production, desktop publishing, and game design. For my final paper, I prepared a middle-thesis on how videogames can be seen as an art form, praised by its uniqueness.</p>
            </div>
    )
  },
  {
  date: "First half of 2015",
  className: "volunteer",
  icon: <FontAwesomeIcon icon={faHandshakeAngle} />,
  render: (
            <div>
              <img className="vertical-timeline-element-img bounce" alt="serralves"
                   src={serralves}/>
              <h3 className="vertical-timeline-element-title">Room Assistant</h3>
              <h4 className="vertical-timeline-element-subtitle">Fundação de Serralves</h4>
              <h4 className="vertical-timeline-element-subtitle">Porto, Portugal</h4>
              <p className="small">For some time, I volunteered as the vigilant of the museum's library.</p>
            </div>
    )
  },
  {
  date: "November 2015",
  className: "degree",
  icon: <FontAwesomeIcon icon={faBuildingColumns} />,
  render: (
            <div>
              <h3 className="vertical-timeline-element-title">Head of the Student Council</h3>
              <h4 className="vertical-timeline-element-subtitle">ISTEC - Instituto Superior de Tecnologias Avançadas</h4>
              <h4 className="vertical-timeline-element-subtitle">Porto, Portugal</h4>
              <p className="small">Social and school work are things that appeal to me, so, in my second year, I presented my electoral list to the Student Council. I ended up being elected the head of the Student Council at ISTEC Porto. My team and I turned the council upside down and, despite being the president, I was also responsible for the branding and marketing-related tasks like creating a new logo, <a href="javascript:;" id="9" onClick={(e)=>expandItem(e)}>website</a>, and promoting student events. </p>
            </div>
    )
  },
  {
  date: "January 2018",
  className: "work",
  icon: <FontAwesomeIcon icon={faBriefcase} />,
  render: (
            <div>
              <img className="vertical-timeline-element-img bounce" alt="highplay"
                   src={highplay}/>
              <h3 className="vertical-timeline-element-title">Digital Specialist</h3>
              <h4 className="vertical-timeline-element-subtitle"><b>Part-time</b> | High Play Gaming</h4>
              <h4 className="vertical-timeline-element-subtitle">Vila Nova de Gaia, Portugal</h4>

              <p className="small">During my time at ISTEC, one of my colleagues invited me to work as a UI/UX Designer and Front-end Developer on the development of a gamification platform called <a href="javascript:;" id="6" onClick={(e)=>expandItem(e)}>High Play Games</a>. I also created editorial content, web apps, promo animations, and project management tools. During this time, I also worked on High Play's online shop and created a new magento one, <a href="javascript:;" id="6" onClick={(e)=>expandItem(e)}> EatPortugallo </a>, from scratch.</p>
            </div>
    )
  },
  {
  date: "Mid 2018 until end 2019",
  className: "volunteer",
  icon: <FontAwesomeIcon icon={faHandshakeAngle} />,
  render: (
            <div>
              <img className="vertical-timeline-element-img bounce" alt="Design for Change"
                   src={dfc}/>
              <h3 className="vertical-timeline-element-title">Camera Operator and Digital specialist</h3>
              <h4 className="vertical-timeline-element-subtitle">MCBA | Design for Change Portugal</h4>
              <h4 className="vertical-timeline-element-subtitle">Vila Nova de Gaia, Portugal</h4>
              <p className="small">I worked with the <a href="https://www.facebook.com/MINI.MCBA/" target="_blank" rel="noopener noreferrer">MCBA</a> team, and once a week I recorded junior basketball games. I also participated in the Design for Change volunteer program, helping them with their social media channels and website.</p>
              </div>
    )
  },{
  date: "June 2019",
  className: "volunteer",
  icon: <FontAwesomeIcon icon={faHandshakeAngle} />,
  render: (
            <div>
              <img className="vertical-timeline-element-img bounce" alt="Jury"
                   src={esic}/>
              <h3 className="vertical-timeline-element-title">Jury member</h3>
              <h4 className="vertical-timeline-element-subtitle">Escola Secundária Inês de Castro</h4>
              <h4 className="vertical-timeline-element-subtitle">Vila Nova de Gaia, Portugal</h4>
              <p className="small">A close friend of mine asked me if I could evaluate the multimedia final projects of some kids in 2019. Due to COVID-19 restrictions and my move to Belgium, I had to decline the invitation for the following year, instead becoming a mentor for some projects.</p>
              </div>
    )
  },
  {
  date: "September 2018",
  className: "work",
  icon: <FontAwesomeIcon icon={faBriefcase} />,
  render: (
            <div>
              <img className="vertical-timeline-element-img bounce" alt="tripleplay"
                   src={tripleplay}/>
              <h3 className="vertical-timeline-element-title">Software Engineer</h3>
              <h4 className="vertical-timeline-element-subtitle">Tripleplay</h4>
              <h4 className="vertical-timeline-element-subtitle">Between Porto (Portugal) and London (UK)</h4>
              <p className="small">In 2018, I accepted a new challenge: to work among other Tripleplay Engineers who were developing IPTV plugin integrations. My tech stack varied mostly between React, Laravel, and ReactPHP Websockets. After awhile, I became responsible for creating a <a href="https://tripleplay.tv/social-media-plugin-support/" target="_blank" rel="noopener noreferrer">social media integration</a>. I had the opportunity to present this plugin at the <a href="https://tripleplay.tv/social-media-plugin-support/" target="_blank" rel="noopener noreferrer">Tottenham Hotspur Stadium</a>. My main responsibility there was to design and develop a <a href="javascript:;" id="12" onClick={(e)=>expandItem(e)}>client portal app</a>, used to manage multiple screens and venues.</p>
            </div>
    )
  },
   {
  date: 'June 2020',
  className: "life",
  icon: <FontAwesomeIcon icon={faTruckMoving} />,
  render: (
            <div>
              <h3 className="vertical-timeline-element-title">Moved to Brussels</h3>
              <h4 className="vertical-timeline-element-subtitle">Brussels, Belgium</h4>
           </div>
    )
  },
  {
  date: "June 2020",
  className: "work",
  icon: <FontAwesomeIcon icon={faBriefcase} />,
  render: (
            <div>
              <img className="vertical-timeline-element-img bounce" alt="euroclear"
                   src={euroclear}/>
              <h3 className="vertical-timeline-element-title">Lead Web User Coordinator</h3>
              <h4 className="vertical-timeline-element-subtitle">Euroclear</h4>
              <h4 className="vertical-timeline-element-subtitle">Brussels, Belgium</h4>
              <p className="small">Currently, my job is to contribute to the group's overall digital strategy and client experience, including identifying opportunities through my knowledge of the latest digital channel trends. I work with multiple stakeholders to implement Euroclear's design system and also help manage the operation of website portals and other digital applications, as well as the training of internal contributors on how to use the CMS and email systems. My goal is to align Euroclear's digital channels with best practises, such as regulatory compliance and bringing user insights through website and social media analytics reporting.</p>
            </div>
    )
  }
]