import React from 'react';
import { toElement as scrollToElement } from 'utils/scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTimes, faDice, faBezierCurve, faHashtag } from '@fortawesome/free-solid-svg-icons'
import {   faPhp, faJs, faHtml5, faSketch, faReact, faLaravel, faMagento } from '@fortawesome/free-brands-svg-icons'

import VAM from 'images/t/VAM_T.png';
import stargame from 'images/t/stargame_t.png';
import eatportugallo from 'images/t/eatportugallo_t.png';
import aeistecp from 'images/t/aeistecp.jpg';
import cashink from 'images/t/cash_ink.jpg';
import docefit from 'images/t/docefit.jpg';
import floppydisk from 'images/t/floppy_disk.jpg';
import highplay from 'images/t/high_play_g.jpg';
import magicash from 'images/t/magicash.jpg';
import moustask from 'images/t/moustask.jpg';
import olx from 'images/t/olx.jpg';
import sushimania from 'images/t/sushi_mania.jpg';
import tripleclient from 'images/t/tripleclient.png';
import eduardov1 from 'images/t/eduardoslab.png';

import cashink_preview from 'images/p/cash_ink.png';
import cashink_logo from 'images/p/cash_ink_logo.jpg';
import eatportugallo_preview from 'images/p/eatportugallo.png';
import eatportugallo_logo from 'images/t/eatportugallo.png';
import highplay_preview from 'images/p/high_play.png';
import magicash_gif from 'images/p/magicash.gif';
import magicash_preview from 'images/p/magicash.png';
import olx_preview from 'images/p/olx_WIP.png';
import stargame_logo from 'images/p/stargame.png';
import stargame_app from 'images/p/stargame_app.PNG';
import stargame_promo from 'images/p/stargame_promo.png';
import sushimania_gif from 'images/p/sushi_mania.gif';
import sushimania_preview from 'images/p/sushi_mania.PNG';
import sushimania_bonus from 'images/p/sushi_mania_bonus.PNG';
import sushimania_logo from 'images/p/sushi_mania_logo.png';
import sushimania_video from 'images/v/sushi_mania.mp4';
import vam_logo from 'images/p/VAM.png';
import vam_feed from 'images/p/vam_feed.png';
import vam_profile from 'images/p/vam_profile.png';
import aeistecp1 from 'images/p/aeistecp/1.png';
import aeistecp2 from 'images/p/aeistecp/2.jpg';
import aeistecp3 from 'images/p/aeistecp/3.png';
import aeistecp4 from 'images/p/aeistecp/4.png';
import docefit1 from 'images/p/docefit/1.jpg';
import docefit2 from 'images/p/docefit/2.JPG';
import docefit3 from 'images/p/docefit/3.JPG';
import docefit4 from 'images/p/docefit/4.png';
import docefit5 from 'images/p/docefit/5.jpg';
import docefit6 from 'images/p/docefit/6.png';
import floppydisk1 from 'images/p/floppydisk/1.PNG';
import floppydisk2 from 'images/p/floppydisk/2.PNG';
import floppydisk3 from 'images/p/floppydisk/3.PNG';
import tripleclient1 from 'images/p/tripleclient/1.png';
import tripleclient2 from 'images/p/tripleclient/2.png';
import tripleclient3 from 'images/p/tripleclient/3.png';
import tripleclient4 from 'images/p/tripleclient/4.jpg';
import tripleclient5 from 'images/p/tripleclient/5.jpg';
import tripleclient_map_view from 'images/p/tripleclient/tripleclient_map_view.png';
import eduardov1_preview from 'images/p/eduardoslab_preview.png';



function expandItem(e) {
    if(document.querySelector('.open')) {document.querySelector('.open').classList.remove('open');}
    const portfolio = document.querySelector('.portfolio-page');
    scrollToElement(portfolio);
    document.querySelector('._' + e.target.id).classList.add('open');
    document.querySelector('.portfolio-h1').classList.add('opentitle');
    document.body.classList.add('open-modal');
}

function closeItem(e) {
    document.querySelector('.open').classList.remove('open');
    document.querySelector('.portfolio-h1').classList.remove('opentitle');
    document.body.classList.remove('open-modal');
}


export default [{
    name: 'sushi-mania',
    render: (
        <div className='portfolio-item _1'>
          <div className='content-container'>
              <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
              <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={sushimania} alt="Sushi Mania" />
                  </div>
                  <div className="tech">
                            <h2 className="title">Sushi Mania</h2>
                  </div>
                  <div className="year">
                        <h2>2016</h2>
                  </div>
              </div>
              <div className="overflow-container"> 
                <div className="well">
                  <small><b>Keywords: </b>Game Design, User Experience, HTML5, Motion Graphics, Scriptwriting</small>
                  <p>Spin Reel game titled "Sushi Mania". From the concept to the initial mockups, I was always involved. This game was completely designed and optimised for the client to be able to comfortably play it both on desktop and mobile. Place your bets on Maneki Neko and try your luck with 12 different symbols! </p>
                  </div>
                  <a href={sushimania_logo} target="_blank" rel="noopener noreferrer"><img src={sushimania_logo} className="img-responsive" alt="Sushi Mania Logo"/></a>
                  <p className="_subtitle">Logo</p>
                  <a href={sushimania_preview} target="_blank" rel="noopener noreferrer"><img src={sushimania_preview} className="img-responsive" alt="Main Screen"/></a>
                  <p className="_subtitle">Main Screen</p>
                  <a href={sushimania_gif} target="_blank" rel="noopener noreferrer"><img src={sushimania_gif} className="img-responsive" alt="Symbols"/></a>
                  <p className="_subtitle">Symbols Animations</p>
                  <a href={sushimania_bonus} target="_blank" rel="noopener noreferrer"><img src={sushimania_bonus} className="img-responsive" alt="Bonus Screen"/></a>
                  <p className="_subtitle">Bonus Screen</p>
                  <video className="img-responsive" alt="promo" controls muted>  <source src={sushimania_video} type="video/mp4"/></video>
                  <p className="_subtitle">Promo video</p>
                  <small className="copyright">Due to copyright restrictions, some features can't be displayed. It serves no commercial purpose.<br/>© <a href="https://fabamaq.com/" target="_blank" rel="noopener noreferrer">FMQ</a></small>
              </div>
        </div>
          <div className='flip-container'>
              <div className="flip-card-front">
                  <img onClick={(e)=>expandItem(e)} id="1" rel="noopener noreferrer" src={sushimania} alt="Sushi Mania" />
                  <div className='portfolio-item__title'>
                      Sushi Mania
                  </div>
              </div>
              <div className="flip-card-back">
                  <div className='portfolio-item__desc'>
                      Spin Reel game titled Sushi Mania.
                  </div>
                  <div className='portfolio-item__icon'>
                      <FontAwesomeIcon icon={faDice} title="Game Design"/>
                      <FontAwesomeIcon icon={faBezierCurve} title="Adobe Creative Tools like After Effects and Photoshop"/>
                  </div>
                  <div className='portfolio-item__links'>
                      <span rel="noopener noreferrer" id="1" onClick={(e)=>expandItem(e)}>More</span>
                  </div>
              </div>
          </div>
      </div>
    )
}, {
    name: 'magicash',
    render: (
        <div className='portfolio-item _3'>
    <div className='content-container'>
        <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
              <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={magicash} alt="Magicash" />
                  </div>
                  <div className="tech">
                            <h2 className="title">Magicash</h2>
                  </div>
                  <div className="year">
                        <h2>2016</h2>
                  </div>
              </div>
             <div className="overflow-container"> 
              <div className="well">
              <small><b>Keywords: </b>Game Design, User Experience, Motion Graphics</small>
              <p>Multicard-bingo game prototype, with Magic and Showtime tematics. This prototype was created as part of a client journey and to be used as the basis for this type of bingo. Something magical will happen after you play it.</p>
              </div>
              <a href={magicash_gif} target="_blank" rel="noopener noreferrer"><img src={magicash_gif} className="img-responsive" alt="Magicash logo animation"/></a>
              <p className="_subtitle">Logo animation</p>
              <a href={magicash_preview} target="_blank" rel="noopener noreferrer"><img src={magicash_preview} className="img-responsive" alt="Main Screen"/></a>
              <p className="_subtitle">Main Screen</p>
              <small className="copyright">Due to copyright restrictions, some features can't be displayed. It serves no commercial purpose.<br/>© <a href="https://fabamaq.com/" target="_blank" rel="noopener noreferrer">FMQ</a></small>
              </div>
        </div>
    <div className='flip-container'>
        <div className="flip-card-front">
            <img onClick={(e)=>expandItem(e)} id="3" rel="noopener noreferrer" src={magicash} alt="Magicash" />
            <div className='portfolio-item__title'>
                Magicash
            </div>
        </div>
        <div className="flip-card-back">
            <div className='portfolio-item__desc'>
               Multicard prototype, inspired by Magic and Showtime.
            </div>
            <div className='portfolio-item__icon'>
                      <FontAwesomeIcon icon={faDice} title="Game Design"/>
                      <FontAwesomeIcon icon={faBezierCurve} title="Adobe Creative Tools like After Effects and Photoshop"/>
            </div>
            <div className='portfolio-item__links'>
                <span rel="noopener noreferrer" id="3" onClick={(e)=>expandItem(e)}>More</span>
            </div>
        </div>
    </div>
</div>
    )
}, {
    name: 'cashink',
    render: (
        <div className='portfolio-item _4'>
    <div className='content-container'>
        <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
              <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={cashink} alt="Cash Ink Bingo" />
                  </div>
                  <div className="tech">
                            <h2 className="title">Cash Ink Bingo</h2>
                  </div>
                  <div className="year">
                        <h2>2015</h2>
                  </div>
              </div>
          <div className="overflow-container"> 
          <div className="well">
              <small><b>Keywords: </b>Game Design, User Experience, Motion Graphics</small>
          <p>Ink everywhere! Cash Ink is an electronic classic bingo prototype that takes the player to a wonderful world of colors. This prototype was created as part of a client journey and to be used as the basis for this type of bingo.</p>
          </div>
          <a href={cashink_logo} target="_blank" rel="noopener noreferrer"><img src={cashink_logo} className="img-responsive" alt="Cash Ink Logo"/></a>
          <p className="_subtitle">Logo</p>
          <a href={cashink_preview} target="_blank" rel="noopener noreferrer"><img src={cashink_preview} className="img-responsive" alt="Cash Ink"/></a>
          <p className="_subtitle">Main Screen</p>
          <small className="copyright">Due to copyright restrictions, some features can't be displayed. It serves no commercial purpose.<br/>© <a href="https://fabamaq.com/" target="_blank" rel="noopener noreferrer">FMQ</a></small>
          </div>
    </div>
    <div className='flip-container'>
        <div className="flip-card-front">
            <img onClick={(e)=>expandItem(e)} id="4" rel="noopener noreferrer" src={cashink} alt="CashInk" />
            <div className='portfolio-item__title'>
                CashInk
            </div>
        </div>
        <div className="flip-card-back">
            <div className='portfolio-item__desc'>
              Digital bingo inspired by a wonderful world of colors.
            </div>
            <div className='portfolio-item__icon'>
                      <FontAwesomeIcon icon={faDice} title="Game Design"/>
                      <FontAwesomeIcon icon={faBezierCurve} title="Adobe Creative Tools like After Effects and Photoshop"/>
            </div>
            <div className='portfolio-item__links'>
                <span rel="noopener noreferrer" id="4" onClick={(e)=>expandItem(e)}>More</span>
            </div>
        </div>
    </div>
</div>
    )
}, 
{
    name: 'tripleclient-',
    render: (
        <div className='portfolio-item _12'>
    <div className='content-container'>
        <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
              <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={tripleclient} alt="TripleClient" />
                  </div>
                  <div className="tech">
                            <h2 className="title">TripleClient</h2>
                  </div>
                  <div className="year">
                        <h2>2019</h2>
                  </div>
              </div>
          <div className="overflow-container"> 
          <div className="well">
              <small><b>Keywords: </b>User Experience, React</small>
          <p>I was tasked with creating an app that allowed Tripleplay platform users to view where their installed digital signage and IPTV end points are on a map of the site or sites that they manage. Once selected, the user can control the channel, power, volume, and input on any given end device, whether that’s a set-top box (STB), media player, smart TV, or interactive screen. This app was designed in Sketch and developed with React + Redux.</p>
          </div>


<a href={tripleclient1} target="_blank" rel="noopener noreferrer"><img src={tripleclient1} className="img-responsive" alt="TripleClient"/></a>
<p className="_subtitle">Controlling a room</p>
<a href={tripleclient2} target="_blank" rel="noopener noreferrer"><img src={tripleclient2} className="img-responsive" alt="Remote"/></a>
<p className="_subtitle">Remote control</p>
<a href={tripleclient3} target="_blank" rel="noopener noreferrer"><img src={tripleclient3} className="img-responsive" alt="Schedule"/></a>
<p className="_subtitle">Scheduling an action</p>
<a href={tripleclient4} target="_blank" rel="noopener noreferrer"><img src={tripleclient4} className="img-responsive" alt="Mobile View"/></a>
<p className="_subtitle">Screens page - Mobile</p>
<a href={tripleclient5} target="_blank" rel="noopener noreferrer"><img src={tripleclient5} className="img-responsive" alt="Mobile"/></a>
<p className="_subtitle">Remote control on a Mobile device (using darkmode)</p>
<img src={tripleclient_map_view} className="img-responsive" alt="Presentation"/>
<p className="_subtitle">Map view showcase</p>



          <small className="copyright">Due to copyright restrictions, some features can't be displayed. It serves no commercial purpose.<br/>© Tripleplay</small>
          </div>
    </div>
    <div className='flip-container'>
        <div className="flip-card-front">
            <img onClick={(e)=>expandItem(e)} id="12" rel="noopener noreferrer" src={tripleclient} alt="TripleClient" />
            <div className='portfolio-item__title'>
                TripleClient
            </div>
        </div>
        <div className="flip-card-back">
            <div className='portfolio-item__desc'>
              Client portal to remotely access and update the content on any display.
            </div>
            <div className='portfolio-item__icon'>
                <FontAwesomeIcon icon={faSketch} title="Sketch"/>
                <FontAwesomeIcon icon={faReact} title="React"/>
            </div>
            <div className='portfolio-item__links'>
                <span rel="noopener noreferrer" id="12" onClick={(e)=>expandItem(e)}>More</span>
            </div>
        </div>
    </div>
</div>
    )
},
 {
    name: 'floppy-disk',
    render: (
        <div className='portfolio-item _7'>
          <div className='content-container'>
              <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
              <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={floppydisk} alt="Floppy Disk" />
                  </div>
                  <div className="tech">
                            <h2 className="title">Floppy Disk</h2>
                  </div>
                  <div className="year">
                        <h2>2013</h2>
                  </div>
              </div>
             <div className="overflow-container"> 
              <div className="well">
              <small><b>Keywords: </b>PHP, jQuery, UX, mySQL, Python, XML</small>
              <p>It's one of my biggest projects ever! With Floppy Disk, designers can generate releases, add editable files, view file history, and track the work of other teammates on the same page. It serves as the hub of operations for the creative team, and it is from this app that information is passed on to developer teams. I was 100% responsible for this project, making me a developer, tester, and designer - all at the same time. The creative team at FMQ uses this app daily and, as I was leaving the company, I was tasked with training new collaborators so they could take over the continuous growth of Floppy Disk.</p>
              </div>
              <a target="_blank" rel="noopener noreferrer" href={floppydisk1}><img src={floppydisk1} className="img-responsive" alt="FD Games Explorer"/></a>
              <p className="_subtitle">Games</p>
              <a target="_blank" rel="noopener noreferrer" href={floppydisk2}><img src={floppydisk2} className="img-responsive" alt="FD File Preview"/></a>
              <p className="_subtitle">File preview</p>
              <a target="_blank" rel="noopener noreferrer" href={floppydisk3}><img src={floppydisk3} className="img-responsive" alt="FD Releases"/></a>
              <p className="_subtitle">Releases</p>
          <small className="copyright">Due to copyright restrictions, some features can't be displayed. It serves no commercial purpose.<br/>© <a href="https://fabamaq.com/" target="_blank" rel="noopener noreferrer">FMQ</a></small></div>

          </div>
          <div className='flip-container'>
              <div className="flip-card-front">
                  <img onClick={(e)=>expandItem(e)} id="7" rel="noopener noreferrer" src={floppydisk} alt="Floppy Disk" />
                  <div className='portfolio-item__title'>
                      Floppy Disk
                  </div>
              </div>
              <div className="flip-card-back">
                  <div className='portfolio-item__desc'>Asset management app created for graphic, sound and marketing team.</div>
                  <div className='portfolio-item__icon'>
                      <FontAwesomeIcon icon={faSketch} title="Sketch"/>
                      <FontAwesomeIcon icon={faPhp} title="PHP"/>
                  </div>
                  <div className='portfolio-item__links'>
                      <span rel="noopener noreferrer" id="7" onClick={(e)=>expandItem(e)}>More</span>
                  </div>
              </div>
          </div>
      </div>
    )
}, {
    name: 'high-play',
    render: (
        <div className='portfolio-item _2'>
          <div className='content-container'>
              <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
              <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={highplay} alt="High Play Gaming" />
                  </div>
                  <div className="tech">
                      <h2 className="title">High Play Gaming</h2>
                  </div>
                  <div className="year">
                        <h2>2018</h2>
                  </div>
              </div>
             <div className="overflow-container"> 
              <div className="well">
              <small><b>Keywords: </b> UID, HTML5, javascript</small>
              <p>High Play Gaming, High Play Institute's new branch, provides digital solutions and gamification services for corporate environments. After setting up the wireframes and UI, I used the CSS3 animation feature to create the splash screen animation with some SVG graphics.</p>
              </div>

              <a target="_blank" rel="noopener noreferrer" href="http://highplay.games/"><img src={highplay_preview} className="img-responsive" alt="High Play Gaming"/></a>
              <p className="_subtitle">Homepage <a target="_blank" rel="noopener noreferrer" href="http://highplay.games/">(click to visit)</a></p>
              <small className="copyright">It serves no commercial purpose.<br/>© <a href="http://www.highplay.pt/pt/" target="_blank" rel="noopener noreferrer">High Play Institute</a></small></div>

          </div>
          <div className='flip-container'>
              <div className="flip-card-front">
                  <img onClick={(e)=>expandItem(e)} id="2" rel="noopener noreferrer" src={highplay} alt="High Play Gaming" />
                  <div className='portfolio-item__title'>
                      High Play Gaming
                  </div>
              </div>
              <div className="flip-card-back">
                  <div className='portfolio-item__desc'>
                      Single-page website, created for a gaming start-up.
                  </div>
                  <div className='portfolio-item__icon'>
                    <FontAwesomeIcon icon={faSketch} title="Sketch"/>
                    <FontAwesomeIcon icon={faJs} title="Javascript"/>
                    <FontAwesomeIcon icon={faHtml5} title="HTML5 Animations"/>
                  </div>
                  <div className='portfolio-item__links'>
                      <a target="_blank" rel="noopener noreferrer" href="http://highplay.games/">Preview</a>
                      <span rel="noopener noreferrer" id="2" onClick={(e)=>expandItem(e)}>More</span>
                  </div>
              </div>
          </div>
      </div>
    )
}, {
    name: 'voltaaomundo',
    render: (
        <div className='portfolio-item _5'>
    <div className='content-container'>
        <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
              <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={VAM} alt="World Race" />
                  </div>
                  <div className="tech">
                      <h2 className="title">World Race (Volta ao Mundo)</h2>
                  </div>
                  <div className="year">
                        <h2>2018</h2>
                  </div>
              </div>
             <div className="overflow-container"> 
       
        <div className="well">
        <small><b>Keywords: </b>React, Laravel, UI, Marketing, PhoneGap</small>
        <p>Volta ao Mundo (World Race) is a gamified platform with two strands: individual and team, involving all people in a healthy competition environment. In the individual game, there are weekly challenges that fill the energy tank, which is fueled by several different quadrants. The fulfilment of the challenges must be evidenced in the feed through posts (photos or videos), allowing all participants to monitor, share, and interact with the progress of the trip. For this project, I was responsible for the visuals, promotion, UI and front-end.</p></div>
        <a href={vam_logo} target="_blank" rel="noopener noreferrer"><img src={vam_logo} className="img-responsive" alt="Volta ao mundo Logo"/></a>
        <p className="_subtitle">Logo</p>
        <a href={vam_profile} target="_blank" rel="noopener noreferrer"><img src={vam_profile} className="img-responsive" alt="Avatar creator"/></a>
        <p className="_subtitle">User profile</p>
        <a href={vam_feed} target="_blank" rel="noopener noreferrer"><img src={vam_feed} className="img-responsive" alt="Challenge feed"/></a>
        <p className="_subtitle">Challenge feed</p>
        
        <small className="copyright">Due to copyright restrictions, some features can't be displayed. It serves no commercial purpose.<br/>© <a href="http://www.highplay.pt/pt/" target="_blank" rel="noopener noreferrer">High Play Institute</a></small></div>

    </div>
    <div className='flip-container'>
        <div className="flip-card-front">
            <img onClick={(e)=>expandItem(e)} id="5" rel="noopener noreferrer" src={VAM} alt="Volta ao Mundo" />
            <div className='portfolio-item__title'>
                World Race
            </div>
        </div>
        <div className="flip-card-back">
            <div className='portfolio-item__desc'>
              Platform to increase motivation and engagement levels between colleagues.
            </div>
            <div className='portfolio-item__icon'>
                <FontAwesomeIcon icon={faSketch} title="Sketch"/>
                <FontAwesomeIcon icon={faReact} title="React"/>
                <FontAwesomeIcon icon={faLaravel} title="Laravel PHP Framework"/>
            </div>
            <div className='portfolio-item__links'>
                <a target="_blank" rel="noopener noreferrer" href="http://play.highplay.games/">Preview</a>
                <span rel="noopener noreferrer" id="5" onClick={(e)=>expandItem(e)}>More</span>
            </div>
        </div>
    </div>
</div>
    )
},  {
    name: 'stargame',
    render: (
        <div className='portfolio-item _10'>
    <div className='content-container'>
        <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
            <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={stargame} alt="Stargame" />
                  </div>
                  <div className="tech">
                      <h2 className="title">Stargame</h2>
                  </div>
                  <div className="year">
                        <h2>2018</h2>
                  </div>
              </div>
             <div className="overflow-container"> 
       
        <div className="well">
        <small><b>Keywords:</b>React, UI, Marketing, After Effects, PhoneGap</small>
        <p>Stargame is a gamified platform with two strands: individual and team, involving all people in a healthy competition environment. In the individual game, there are weekly challenges that fill the energy tank, which is fueled by several different quadrants. The fulfilment of the challenges must be evidenced in the feed through posts (photos or videos), allowing all participants to monitor, share and interact with the progress of the space journey. For this project, I did some front-end fixes, created a new logo + promo material (sound design included!) and releases a mobile app.</p></div>
       
        <a href={stargame_logo} target="_blank" rel="noopener noreferrer"><img src={stargame_logo} className="img-responsive" alt="Logo"/></a>
        <p className="_subtitle">Logo</p>
 
        <a href={stargame_app} target="_blank" rel="noopener noreferrer"><img src={stargame_app} className="img-responsive" alt="App Screen"/></a>
        <p className="_subtitle">App available in <a href="https://apps.apple.com/pt/app/stargame/id1262921705?l=en" target="_blank" rel="noopener noreferrer">iTunes</a> and <a href="https://play.google.com/store/apps/details?id=com.highplay.games&hl=pt_PT" target="_blank" rel="noopener noreferrer">Play Store</a></p>
        
        <iframe width="720" height="402" src="https://www.youtube.com/embed/GBGUb1Lv7ec?rel=0&amp;showinfo=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

        <p className="_subtitle">Promo for presentations (audio in Portuguese)</p>

        <a href={stargame_promo} target="_blank" rel="noopener noreferrer"><img src={stargame_promo} className="img-responsive" alt="Stargame Infographic"/></a>
        <p className="_subtitle">Game Brochure</p>

        <small className="copyright">Due to copyright restrictions, some features can't be displayed. It serves no commercial purpose.<br/>© <a href="http://www.highplay.pt/pt/" target="_blank" rel="noopener noreferrer">High Play Institute</a></small></div>

    </div>
    <div className='flip-container'>
        <div className="flip-card-front">
            <img onClick={(e)=>expandItem(e)} id="10" rel="noopener noreferrer" src={stargame} alt="Stargame" />
            <div className='portfolio-item__title'>
                Stargame
            </div>
        </div>
        <div className="flip-card-back">
            <div className='portfolio-item__desc'>
              Platform to increase motivation and engagement levels between colleagues.
            </div>
            <div className='portfolio-item__icon'>
                <FontAwesomeIcon icon={faBezierCurve} title="Adobe Web Tools like Illustrator and Phone Gap"/>
                <FontAwesomeIcon icon={faReact} title="React"/>
                <FontAwesomeIcon icon={faLaravel} title="Laravel PHP Framework"/>
            </div>
            <div className='portfolio-item__links'>
                <a target="_blank" rel="noopener noreferrer" href="http://www.stargame.pt/">Preview</a>
                <span rel="noopener noreferrer" id="10" onClick={(e)=>expandItem(e)}>More</span>
            </div>
        </div>
    </div>
</div>
    )
}, {
    name: 'eatportugallo',
    render: (
        <div className='portfolio-item _6'>
    <div className='content-container'>
        <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
            <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={eatportugallo} alt="EatPortugallo" />
                  </div>
                  <div className="tech">
                      <h2 className="title">EatPortugallo</h2>
                  </div>
                  <div className="year">
                        <h2>2018</h2>
                  </div>
              </div>
             <div className="overflow-container"> 

        <div className="well">
        <small><b>Keywords:</b>Sketch, Illustrator, Magento, PHP, Social Media, Analytics</small>
        <p>The EatPortugallo team wanted to create a digital space that could match the level of care, energy, and community of their brand operation. The design had to be editorial, along with an e-commerce component that was fast, beautiful, and forward-thinking... Incorporating a distinct Portuguese flavour with the ability to link content stories and purchases has been a long-term goal of mine. With an idea of what the store could look like, I started drafting some mockups for logos and interfaces, followed by the development (from a vanilla Magento 2.1 installation) and making the tweaks to achieve an ideal design/template. Magento is easy to work with and implement since it is written in PHP.</p>
        </div>
        <a target="_blank" rel="noopener noreferrer" href="http://eatportugallo.pt/"><img src={eatportugallo_logo} className="img-responsive" alt="EatPortugallo logo"/></a>
        <p className="_subtitle">Logo </p>
        <a target="_blank" rel="noopener noreferrer" href="http://eatportugallo.pt/"><img src={eatportugallo_preview} className="img-responsive" alt="EatPortugallo"/></a>
        <p className="_subtitle">Homepage <a target="_blank" rel="noopener noreferrer" href="http://eatportugallo.pt/">(inactive as of 2020)</a></p>
        <small className="copyright">It serves no commercial purpose.<br/> © <a href="http://eatportugallo.pt" target="_blank" rel="noopener noreferrer">EatPortugallo</a></small></div>

    </div>
    <div className='flip-container'>
        <div className="flip-card-front">
            <img onClick={(e)=>expandItem(e)} id="6" rel="noopener noreferrer" src={eatportugallo} alt="EatPortugallo" />
            <div className='portfolio-item__title'>
               EatPortugallo
            </div>
        </div>
        <div className="flip-card-back">
            <div className='portfolio-item__desc'>
              Portuguese online store, with an Atlantic flavour.
            </div>
            <div className='portfolio-item__icon'>
                <FontAwesomeIcon icon={faBezierCurve} title="Adobe Design Tools like Illustrator"/>
                <FontAwesomeIcon icon={faReact} title="Magento"/>
            </div>
            <div className='portfolio-item__links'>
                <span rel="noopener noreferrer" id="6" onClick={(e)=>expandItem(e)}>More</span>
            </div>
        </div>
    </div>
</div>
    )
}, {
    name: 'doce-fit',
    render: (
        <div className='portfolio-item _8'>
          <div className='content-container'>
              <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
            <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={docefit} alt="Doce.Fit" />
                  </div>
                  <div className="tech">
                      <h2 className="title">Doce.Fit</h2>
                  </div>
                  <div className="year">
                        <h2>2017</h2>
                  </div>
              </div>
             <div className="overflow-container"> 
                <div className="well">
                <small><b>Keywords:</b> Graphic Design, Brand Management, Social Media</small>
                <p>Doce.Fit is a pastry shop that bakes treats the Portuguese way with less than 70% sugar. The idea was to recreate an authentic experience - with a branding concept developed around the sea culture that emphasises the genuine flavour of Pastel de Nata and Portuguese uniqueness.</p></div>

                <a href={docefit6} target="_blank" rel="noopener noreferrer"><img src={docefit6} className="img-responsive" alt="Logo"/></a>
                <p className="_subtitle">Logo in white background</p>
                <a href={docefit1} target="_blank" rel="noopener noreferrer"><img src={docefit1} className="img-responsive" alt="icon"/></a>
                <p className="_subtitle">Icon</p>
                <a href={docefit2} target="_blank" rel="noopener noreferrer"><img src={docefit2} className="img-responsive" alt="Packaging"/></a>
                <p className="_subtitle">Packaging</p>
                <a href={docefit3} target="_blank" rel="noopener noreferrer"><img src={docefit3} className="img-responsive" alt="Social Media"/></a>
                <p className="_subtitle">Pastel de nata</p>
                <a href={docefit4} target="_blank" rel="noopener noreferrer"><img src={docefit4} className="img-responsive" alt="Flyer"/></a>
                <p className="_subtitle">Flyer</p>
                <a href={docefit5} target="_blank" rel="noopener noreferrer"><img src={docefit5} className="img-responsive" alt="Instagram Promo"/></a>
                <p className="_subtitle">Instagram promo</p>
                 <small className="copyright">It serves no commercial purpose.<br/> © <a href="https://www.facebook.com/docepontofit/" target="_blank" rel="noopener noreferrer">Doce.Fit</a></small></div>

          </div>
          <div className='flip-container'>
              <div className="flip-card-front">
                  <img onClick={(e)=>expandItem(e)} id="8" rel="noopener noreferrer" src={docefit} alt="Doce.Fit" />
                  <div className='portfolio-item__title'>
                      Doce.Fit
                  </div>
              </div>
              <div className="flip-card-back">
                  <div className='portfolio-item__desc'>
                      Pastry shop brand & communication
                  </div>
                  <div className='portfolio-item__icon'>
                    <FontAwesomeIcon icon={faBezierCurve} title="Adobe Design Tools like Illustrator, Photoshop"/>
                    <FontAwesomeIcon icon={faHashtag} title="Social Media creator pages"/>
                  </div>
                  <div className='portfolio-item__links'>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.nit.pt/comida/nova-pastelaria-fit-matosinhos">Press</a>
                      <span rel="noopener noreferrer" id="8" onClick={(e)=>expandItem(e)}>More</span>
                  </div>
              </div>
          </div>
      </div>
    )
}, {
    name: 'ae-istec-p',
    render: (
        <div className='portfolio-item _9'>
          <div className='content-container'>
              <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
            <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={aeistecp} alt="AE ISTEC P" />
                  </div>
                  <div className="tech">
                      <h2 className="title">AE ISTEC Porto</h2>
                  </div>
                  <div className="year">
                        <h2>2015</h2>
                  </div>
              </div>
             <div className="overflow-container"> 

              <div className="well">
              <small><b>Keywords:</b> Graphic & Web Design, Digital Marketing</small>
              <p>In 2015, I was elected the new Head of the Student Council at ISTEC Porto. For years, the lack of communication brought trust issues between the council and the students. My team and I turned the council upside down and, despite being the president, I was also responsible for the branding and marketing-related tasks like creating a new logo, website, and promoting student events. You can check a snapshot of the website (dated 2015) <a target="_blank" rel="noopener noreferrer" href="assets/aeistecp/index.html">here</a>.</p></div>
              <a href={aeistecp1} target="_blank" rel="noopener noreferrer"><img src={aeistecp1} className="img-responsive" alt="Logos"/></a>
              <p className="_subtitle">Old vs. New logo</p>
              <a href={aeistecp2} target="_blank" rel="noopener noreferrer"><img src={aeistecp2} className="img-responsive" alt="Social Media"/></a>
              <p className="_subtitle">Social Media Promo</p>
              <a href={aeistecp3} target="_blank" rel="noopener noreferrer"><img src={aeistecp3} className="img-responsive" alt="Flyers"/></a>
              <p className="_subtitle">Flyer promoting school elections "Unleash your secret talent"</p>
              <a href={aeistecp4} target="_blank" rel="noopener noreferrer"><img src={aeistecp4} className="img-responsive" alt="Flyers"/></a>
              <p className="_subtitle">Flyers promoting #isTech25 "Voice and audio treatment for advertising"</p>
              <small className="copyright">It serves no commercial purpose.<br/> ©  <a href="http://istec.pt/" target="_blank" rel="noopener noreferrer">ISTEC</a></small></div>

          </div>
          <div className='flip-container'>
              <div className="flip-card-front">
                  <img onClick={(e)=>expandItem(e)} id="9" rel="noopener noreferrer" src={aeistecp} alt="AE ISTEC P" />
                  <div className='portfolio-item__title'>
                      AE ISTEC Porto
                  </div>
              </div>
              <div className="flip-card-back">
                  <div className='portfolio-item__desc'>
                      Student council website and promotion
                  </div>
                  <div className='portfolio-item__icon'>
                    <FontAwesomeIcon icon={faBezierCurve} title="Adobe Design Tools like Illustrator, Photoshop"/>
                    <FontAwesomeIcon icon={faJs} title="Javascript"/>
                                        <FontAwesomeIcon icon={faHtml5} title="HTML5 Animations"/>
                    <FontAwesomeIcon icon={faHashtag} title="Social Media creator pages"/>
                  </div>
                  <div className='portfolio-item__links'>
                    <a target="_blank" rel="noopener noreferrer" href="assets/aeistecp/index.html">Preview</a>
                      <span rel="noopener noreferrer" id="9" onClick={(e)=>expandItem(e)}>More</span>
                  </div>
              </div>
          </div>
      </div>
    )
}, {
    name: 'olx-wip',
    render: (
        <div className='portfolio-item _11'>
          <div className='content-container'>
              <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
            <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={olx} alt="OLX" />
                  </div>
                  <div className="tech">
                      <h2 className="title">OLX Mock-up</h2>
                  </div>
                  <div className="year">
                        <h2>2017</h2>
                  </div>
              </div>
             <div className="overflow-container"> 

              <div className="well">
              <small><b>Keywords:</b> UI Design</small>
              <p>This is a school project I originally worked on for a few hours, with an emphasis on simplicity and making the shopping more visually appealing. The aim is to focus on increasing user engagement of the application through the use of a shopping cart, where users can negotiate with the item sellers and find the best deal. I will be updating this project with some previews and concepts.</p></div>
                  <a target="_blank" rel="noopener noreferrer" href={olx_preview}><img src={olx_preview} className="img-responsive" alt="OLX Homepage"/></a>
                  <p className="_subtitle">Sketch 01</p>
              <small className="copyright">This is only a user experience and design exercise, there is no link between OLX and me. It serves no commercial purpose.<br/> © <a href="https://www.olx.pt/" target="_blank" rel="noopener noreferrer">OLX</a></small></div>

          </div>
          <div className='flip-container'>
              <div className="flip-card-front">
                  <img onClick={(e)=>expandItem(e)} id="11" rel="noopener noreferrer" src={olx} alt="OLX" />
                  <div className='portfolio-item__title'>
                      OLX 
                  </div>
              </div>
              <div className="flip-card-back">
                  <div className='portfolio-item__desc'>
                     A mock-up for OLX homepage, an online marketplace.
                  </div>
                  <div className='portfolio-item__icon'>
                    <FontAwesomeIcon icon={faSketch} title="Sketch"/>
                  </div>
                  <div className='portfolio-item__links'>
                      <span rel="noopener noreferrer" id="11" onClick={(e)=>expandItem(e)}>More</span>
                  </div>
              </div>
          </div>
      </div>
    )
}, {
    name: 'eduardo-v1',
    render: (
        <div className='portfolio-item _13'>
          <div className='content-container'>
              <div className="close" onClick={(e)=>closeItem(e)}><FontAwesomeIcon icon={faTimes} className="close fa-2xl"/></div>
            <div className="portfolio-item-open-header">
                  <div className="logo">
                        <img src={eduardov1} alt="personal-site" />
                  </div>
                  <div className="tech">
                      <h2 className="title">Eduardo's Lab</h2>
                  </div>
                  <div className="year">
                        <h2>2017</h2>
                  </div>
              </div>
             <div className="overflow-container"> 

              <div className="well">
              <small><b>Keywords:</b> UI Design, HTML, React</small>
                        <p>As a gamer myself, the idea was to create a site based on the retro visual pattern, very typical of the 90s gaming scene. This was the first step to pursue a new challenge while on Fabamaq and creating my legacy.</p><p>For the current website, I wanted to use React and create something brand new. The goal was to have a sleek and modern design for a Digital Specialist portfolio website. After some research on modern and minimalistic designs, I came up with a rough idea of how I could make my message go through in a unique way: always with a <a href="javascript:;"onClick={(e) => document.getElementsByClassName('pikachu')[0].click()}>twist</a>!</p></div>
                  <a target="_blank" rel="noopener noreferrer" href="assets/eduardoslab/index.html"><img src={eduardov1_preview} className="img-responsive" alt="Eduardo's Lab 2017 Preview"/></a>
                  <p className="_subtitle">2017 Version (Archive)</p>
                  </div>

          </div>
          <div className='flip-container'>
              <div className="flip-card-front">
                  <img onClick={(e)=>expandItem(e)} id="13" rel="noopener noreferrer" src={eduardov1} alt="Eduardo's Lab" />
                  <div className='portfolio-item__title'>
                      Eduardo's Lab 
                  </div>
              </div>
              <div className="flip-card-back">
                  <div className='portfolio-item__desc'>
                     The first version of this site, with a retro aesthetic
                  </div>
                  <div className='portfolio-item__icon'>
                    <FontAwesomeIcon icon={faSketch} title="Sketch"/>
                  </div>
                  <div className='portfolio-item__links'>
                   <a target="_blank" rel="noopener noreferrer" href="assets/eduardoslab/index.html">Preview</a>
                      <span rel="noopener noreferrer" id="13" onClick={(e)=>expandItem(e)}>More</span>
                  </div>
              </div>
          </div>
      </div>
    )
}]