import React from "react";
import PropTypes from "prop-types";
import ScrollToNext from "components/ScrollToNext";
import ScrollToPrevious from 'components/ScrollToPrevious';
import Pikachu from "components/Pikachu";
import "./style.scss";

const AboutPage = (props, context) => {
  const {
    theme: { colorPrimary, colorHighlight, textPrimary, colorAlternate }
  } = context;

  return (
    <div className="about-page">
      <style jsx="true">
        {`
          .highlight {
            background-color: ${colorHighlight};
          }
          *::selection {
            background-color: ${colorHighlight};
          color: ${textPrimary};
          }
          p::selection {
              background-color: ${colorHighlight};
              color: ${textPrimary};
          }
        `}
      </style>
      <div className="content-grid">
      <ScrollToPrevious pageSelector=".landing-page" />
        <h1 style={{ color: colorPrimary }}>Welcome to my lab!</h1>
        <div className="about-wrapper">
          <div className="about-content" style={{ color: textPrimary }}>

<p>Hi there!</p>
<p>I'm a digital problem solver with a passion for turning complex problems into intuitive, dynamic user journeys. Originally from sunny Porto, I now call Brussels my home base. When I'm not busy crafting exceptional digital experiences, you can find me sharing my thoughts on Reddit, drawing beautiful pixels, challenging myself with Super Mario, or cooking up a storm in the kitchen.</p>

<p>With years of experience working on various digital channels and projects, I've acquired extensive knowledge in user experience design (UED) and technical skills. I'm a bridge-builder, adept at connecting the creative, technical, and commercial pipelines to deliver exceptional results. Collaboration is my favourite phase of any project. Working with colleagues to understand their needs and help them achieve their goals is what drives me. As ideas flow and we engage in the collaborative process, I let my creativity burst out to achieve perfection.</p>

<p>On this page, you'll find some of my favourite projects, ranging from UI/UX mock-ups to React and even eCommerce.</p>

<p>If you're looking for a digital problem solver who can deliver exceptional results, don't hesitate to get in touch!</p>
            
            <Pikachu />
          </div>
        </div>
      </div>
      <ScrollToNext pageSelector=".timeline-page" />
    </div>
  );
};

AboutPage.contextTypes = {
  theme: PropTypes.any
};

export default AboutPage;
