import React from 'react';
import PropTypes from 'prop-types';
import CV from 'assets/CV_2022.pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faAt, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import {  faLinkedin, faFlickr, faInstagram } from '@fortawesome/free-brands-svg-icons'

import './style.scss';

const SocialIcons = (props, context) => {
  const { theme: { colorPrimary } } = context;
  
  return (
    <div className="social-icons animate-icons">
        <a target="_blank" rel="noopener noreferrer" title="LinkedIn" href="https://linkedin.com/in/eduardormw" style={ { color: colorPrimary } }>            <FontAwesomeIcon icon={faLinkedin} /></a>
        <a target="_blank" rel="noopener noreferrer" title="Flickr" href="https://www.flickr.com/photos/eduardormw/albums" style={ { color: colorPrimary } }><FontAwesomeIcon icon={faFlickr} /></a>
        <a target="_blank" rel="noopener noreferrer" title="Instagram" href="https://instagram.com/eduardormw" style={ { color: colorPrimary } }><FontAwesomeIcon icon={faInstagram}/></a>
        <a target="_blank" rel="noopener noreferrer" title="Email-me!" href="mailto:eduardo_martins@outlook.com" style={ { color: colorPrimary } }><FontAwesomeIcon icon={faAt}/></a>
        <a target="_blank" rel="noopener noreferrer" title="Save my CV" href={ CV } style={ { color: colorPrimary } }><FontAwesomeIcon icon={faFilePdf}/></a>
      </div>
  );
};

SocialIcons.contextTypes = {
  theme: PropTypes.any
};

export default SocialIcons;