import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i_pikachu from '../../images/pikachu.gif'
import i_pikachuAttack from '../../images/pikachuThunder.gif'
import './style.scss';

class Pikachu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: i_pikachu
    };
  }

  boxClick = (e) => {
    document.body.classList.add('thunder');
    this.setState({
      src: i_pikachuAttack
    })

    setTimeout(() => {
      this.setState({ 
            src: i_pikachu
            });
        document.body.classList.remove('thunder');
    }, 600);
  }

  render() {
    const {
      switchTheme
    } = this.context;
    
    return (
       <div className="magic-wand" onClick={(e) => setTimeout(() => {switchTheme()}, 600)}>
          <img src={this.state.src} alt="pikachu" onClick={this.boxClick} className="pikachu"/> 
          <div className="magic-text-show">Pika pi! (Click me)</div>
          <div className="magic-text-desc">pet me so I can electrify the page <br/> flashing lights warning!</div>
        </div>
    );
  }
}

Pikachu.contextTypes = {
  switchTheme: PropTypes.func
};

export default Pikachu;
