import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toElement as scrollToElement } from 'utils/scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faChevronDown } from '@fortawesome/free-solid-svg-icons'

import './style.scss';

class ScrollToNext extends Component {
  scrollToNext() {
    const { pageSelector } = this.props;
    const nextPage = document.querySelector(pageSelector);
    scrollToElement(nextPage);
  }

  render() {
  const { pageSelector } = this.props;
  const { theme: { colorPrimary } } = this.context;
  
  let scrollNextClass = 'scroll-to-next';
  if(pageSelector === '.about-page') { scrollNextClass += ' activeStart'; }
  console.log(scrollNextClass);

  return (
      <div className={ scrollNextClass } onClick={(e) => this.scrollToNext()}>
        <div className="arrow bounce" style={{ color: colorPrimary }}>
          <FontAwesomeIcon icon={faChevronDown} className="fa-2xl" />
        </div>
      </div>
    );
  }
}

ScrollToNext.propTypes = {
  pageSelector: PropTypes.string
};

ScrollToNext.contextTypes = {
  theme: PropTypes.any
};

export default ScrollToNext;
